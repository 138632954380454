<template>
  <div class="content asset-change-list">
    <section style="margin-bottom: 1%">
      <a-button type="primary" @click="createHandle">
        <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
        {{ $t('新增资产变更单') }}
      </a-button>
    </section>
    <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.orderCode"
            :placeholder="$t('请输入单据编号')"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <CommonTable
      ref="CommonTableRef"
      :statusList="asset_operate_status"
      :columns.sync="columns"
      :loading="loading"
      :dataSource="tableData"
      :showSelect="true"
      :scroll="{ x: 1500 }"
      :selectedRowKeys.sync="selectedRowKeys"
      :total="total"
      rowKey="id"
      @select="onSelectChange"
      @search="getList"
    >
      <template v-slot:header>
        <div class="flex-ct">
          <OperateBtn
            api="operateAssetChange"
            :text="$t('审核')"
            :params="{
              action: 'CHECK',
              idList: selectedRowKeys,
            }"
            :disabled="selectedRows.length === 0"
            @success="searchHandle"
          />
          <VoucherBtn
            class="ml10"
            :docType="docType"
            :selectedRows="selectedRows"
            :disabled="selectedRows.length === 0"
            @close="searchHandle"
          />
        </div>
      </template>
      <template v-slot:orderCode="{ text, record }">
        <a @click="viewDetail(record)">{{ text }}</a>
      </template>
      <template v-slot:changeAmount="{ text }">
        <PriceInput :value="text" :showTxt="true" />
      </template>
      <template v-slot:assetCode="{ record }">
        <div
          v-for="item in record.detailList"
          :key="item.id"
          class="text-link"
          @click="jumpSoucePage(item.assetCode, item.assetCardId)"
        >
          {{ item.assetCode }}
        </div>
      </template>
      <template v-slot:assetName="{ record }">
        <div v-for="item in record.detailList" :key="item.id">
          {{ item.assetName }}
        </div>
      </template>
    </CommonTable>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
import { convertKeysToCamelCase, jumpSoucePage } from '@/common'
export default {
  name: 'AssetchangeList',
  components: { CommonTable },
  data() {
    return {
      docType: 'Assetchange',
      formData: {},
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      total: 0,
      loading: false,

      columns: [
        {
          title: this.$t('单据编号'),
          dataIndex: 'orderCode',
          width: 200,
          scopedSlots: { customRender: 'orderCode' },
        },
        {
          title: this.$t('货主组织'),
          dataIndex: 'ownerBusinessUnitName',
        },
        {
          title: this.$t('记账日期'),
          dataIndex: 'accountingDate',
          customRender: (text) => text?.split(' ')?.[0],
        },
        {
          title: this.$t('变更项目'),
          dataIndex: 'changeItemList',
          customRender: (text) => text?.map((item) => item.itemName)?.join(';'),
        },
        {
          title: this.$t('资产编码'),
          dataIndex: 'assetCode',
          scopedSlots: { customRender: 'assetCode' },
          width: 250,
        },
        {
          title: this.$t('资产名称'),
          dataIndex: 'assetName',
          scopedSlots: { customRender: 'assetName' },
        },
        {
          title: this.$t('单据状态'),
          dataIndex: 'orderStatus',
          customRender: (text) => this.assetOperateStatusMapping[text],
        },
        {
          title: this.$t('备注'),
          dataIndex: 'remark',
        },
        {
          title: this.$t('是否生成凭证'),
          dataIndex: 'voucherGenerated',
          width: 100,
          customRender: (text) => (text ? this.$t('是') : this.$t('否')),
        },
        {
          title: this.$t('凭证号'),
          dataIndex: 'voucherNo',
          width: 100,
        },
      ],
    }
  },
  activated() {
    this.searchHandle()
  },
  computed: {
    ...mapState(['asset_operate_status']),
    ...mapGetters(['assetOperateStatusMapping']),
  },
  methods: {
    jumpSoucePage,
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.searchHandle(1)
    },
    searchHandle(pageNo) {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    createHandle() {
      this.$router.push({ name: 'assetChangeDetail' })
    },
    async getList(params) {
      this.loading = true
      await http({
        url: api.getAssetChangeList,
        data: {
          ...this.formData,
          ...params,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    viewDetail(record) {
      this.addPane({
        view: 'assetChangeDetail/' + record.orderCode,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.orderCode,
        },
        route: `/asset/assetChangeDetail?id=${record.id}&sn=${record.orderCode}`,
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  },
}
</script>
